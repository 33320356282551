const theme = {
  colors: {
    primary: '#3D63AE',
    bg: '#fff',
    black: '#000',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',

    greyDark: '#303643',
    greyDarker: '#1c252b',
    blue900: "#192c4c",
    blue800: "#2a4a7f",
    blue700: "#3a67b2",
    blue600: "#4b84e5",
    blue500: "#5393fe",
    blue400: "#73abff",
    blue300: "#cbdfff",
    blue200: "#e3efff",
    blue100: "#f1f8ff",

    green900: "#1b5e20",
    green800: "#2e7d32",
    green700: "#388e3c",
    green600: "#43a047",
    green500: "#4caf50",
    green400: "#81c784",
    green300: "#a5d6a7",
    green200: "#c8e6c9",
    green100: "#e8f5e9",

    grey900: "#111010",
    grey800: "#3a342a",
    grey700: "#625747",
    grey600: "#796F58",
    grey550: "#b6a498",
    grey500: "#c6b8af",
    grey400: "#ded2c0",
    grey300: "#E5dad3",
    grey200: "#efe8e3",
    grey100: "#F8F5F3",

    gray900: "#111010",
    gray800: "#3D3F42",
    gray700: "#73737D",
    gray600: "#A9AAAB",
    gray500: "#BEBFC0",
    gray400: "#D4D4D5",
    gray300: "#E9EAEA",
    gray200: "#F2F2F2",
    gray100: "#F9F9FA",

    orange600: "#DC6039",
    orange500: "#F46B3F",
    orange200: "#FDE1D9",
    orange100: "#FEF0EC",

    pink900: "#804980",
    pink800: "#B366B3",
    pink700: "#CC74CC",
    pink600: "#E683E6",
    pink500: "#FF91FF",
    pink400: "#FFA7FF",
    pink300: "#FFC8FF",
    pink200: "#FFFEFF",
    pink100: "#FFF4FF",

    purple900: "#36254C",
    purple800: "#5B3E80",
    purple700: "#7f56b3",
    purple600: "#A36FE6",
    purple500: "#B57BFF",
    purple400: "#C495FF",
    purple300: "#D3B0FF",
    purple200: "#F0E5FF",
    purple100: "#F8F2FF",

    teal900: "#006064",
    teal800: "#00838f",
    teal600: "#159f9f",
    teal500: "#17B1B1",
    teal200: "#D1EFEF",
    teal100: "#E8F7F7",
  },
  maxWidth: '1000px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
  //dimensions
  //pixels
  maxwidthDesktop: '1400px',
  maxwidthLayout: '1200px',
  maxwidthiPadPro: '1000px',
  maxwidthTablet: '768px',
  maxwidthMobile: '600px',

  //ems
  paddingHorizontalDesktop: '4em',
  paddingHorizontalTablet: '2.5em',
  paddingHorizontalMobile: '2em',
}

export default theme
